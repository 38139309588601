import Aos from 'aos';
import HeaderMenu from './headerMenu';
var documentReady = function () {
    Aos.init();
    HeaderMenu();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
