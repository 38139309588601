export default function HeaderMenu() {
    const headerClass = 'header';
    const headerScrolledClass = 'scrolled';
    const headerNav = document.querySelector(`.${headerClass}`);
    const mobileMenuBtn = document.getElementById('headerMobile');
    const headerLinks = document.querySelectorAll(`.${headerClass} a`);

    headerLinks.forEach(link => {
        link.addEventListener('click', () =>
            handleMenu(headerNav, mobileMenuBtn)
        );
    });
    mobileMenuBtn.addEventListener('click', () =>
        openMenu(headerNav, mobileMenuBtn)
    );
    window.addEventListener('scroll', () =>
        handleScroll(headerNav, headerScrolledClass)
    );
    document.addEventListener('click', event => {
        if (!headerNav.contains(event.target)) {
            handleMenu(headerNav, mobileMenuBtn);
        }
    });
}

let lastScrollPosition = 0;
const handleScroll = (element, className) => {
    const scrollPosition = window.scrollY || window.pageYOffset;

    if (scrollPosition > lastScrollPosition) {
        element.classList.add(className);
    } else {
        element.classList.remove(className);
    }
    lastScrollPosition = scrollPosition <= 0 ? 0 : scrollPosition;
};

const openMenu = (headerMenu, buttonMenu) => {
    headerMenu.classList.toggle('active');
    buttonMenu.classList.toggle('active');
};

const handleMenu = (headerMenu, buttonMenu) => {
    if (headerMenu.classList.contains('active')) {
        headerMenu.classList.remove('active');
        buttonMenu.classList.remove('active');
    }
};
